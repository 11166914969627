import { useEffect, useState } from "react";

import Link from "next/link";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Avatar from "./avatar";
import EnrollmentModal from "./enrollmentmodal";
import EnrollmentBtnTooltip from "./enrollmentbtntooltip";
import PriceInfoPopover from "./priceinfopopover";
import WinnerExperienceGain from "./winnerexperiencegain";
import PlacesLeftInfo from "./placesleftinfo";
import ParticipantCount from "./participantcount";
import { getParticipantAvatarClassName } from "../lib/content";
import GamePlace from "./gameplace";
import PlayerPeerRating from "./playerpeerrating";
import ScenarioRating from "./scenariorating";
import HostGrade from "./hostgrade";
import GameExtraInfoPoints from "./gameextrainfopoints";
import GameTeamsSelector from "./gameteamselector";
import { filterParticipantListByTeamId, getGameTeamFromList } from "../lib/teams";
import GameCharacteristics from "./gamecharacteristics";
import GameLevel from "./gamelevel";
import ThumbnailPicture from "./thumbnailpicture";
import { isMeet4USite } from "../lib/coreutils";
import PlayerClan from "./playerclan";

function EnrollmentCardParticipantTable({ participantList, highlightPlayerId }) {
    if (participantList.length == 0) {
        return null;
    }
    return (
        <table className="players-table w-100">
            <thead>
                <tr>
                    <th></th>
                    <th>Игры</th>
                    <th>Побед</th>
                </tr>
            </thead>
            <tbody>
                {participantList.map((participant) => (
                    <tr
                        className={participant.player.id === highlightPlayerId ? "players-table__highlight-row" : ""}
                        key={participant.player.id}
                    >
                        <td>
                            <div className="players-table__participant">
                                <Avatar
                                    player={participant.player}
                                    size={66}
                                    className={
                                        "players-table__participant__avatar" +
                                        getParticipantAvatarClassName(participant)
                                    }
                                />
                                <div>
                                    <Link href={participant.player.url}>
                                        <a className="players-table__participant__name">{participant.player.name}</a>
                                    </Link>{" "}
                                    {participant.player.mafia_nick && (
                                        <>
                                            <br />
                                            {participant.player.mafia_nick}{" "}
                                        </>
                                    )}
                                    {!isMeet4USite() && <>[{participant.player.level}]</>}
                                    <PlayerPeerRating player={participant.player} />
                                    <PlayerClan player={participant.player} className="d-block small" />
                                    <div className="players-table__participant__description">
                                        {participant.player.description}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>{participant.num_games_total}</td>
                        <td>{participant.num_wins_total}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function EnrollmentButton({ game, onEnrollment, setShowEnrollment }) {
    if (!onEnrollment) {
        return null;
    }
    return (
        <EnrollmentBtnTooltip game={game}>
            <button
                className={
                    "btn btn-wide-padding " +
                    (game.is_enrollment_prohibited && !game.is_waiting_list ? "btn-orange" : "btn-primary")
                }
                onClick={() => setShowEnrollment(true)}
            >
                {game.is_waiting_list ? "В лист ожидания" : "Записаться"}
            </button>
        </EnrollmentBtnTooltip>
    );
}

export default function EnrollmentCard({
    game,
    gameDescription,
    gameTeams,
    highlightPlayerId,
    showPlacesLeftInfo,
    onCollapse,
    onEnrollment,
}) {
    const [showEnrollment, setShowEnrollment] = useState(false);
    const [selectedGameTeamId, setSelectedGameTeamId] = useState("all");

    const onSelectGameTeamId = (gameTeamId) => {
        setSelectedGameTeamId(gameTeamId);
        if (gameTeamId == "new") {
            setShowEnrollment(true);
        }
    };

    const gameTeam = getGameTeamFromList(gameTeams, selectedGameTeamId);
    const participantsToShow = filterParticipantListByTeamId(game.participant_list, selectedGameTeamId);

    const half = Math.ceil(participantsToShow.length / 2);
    const participantColumns = [
        participantsToShow.slice(0, half),
        participantsToShow.slice(half, participantsToShow.length),
    ];

    const priceInfoPopover = <PriceInfoPopover game={game} />;

    return (
        <>
            <div className="enrollment__card">
                <div className="enrollment__card__header">
                    <div className="enrollment__card__header__title mb-1">
                        <h1 className="mb-0">
                            <Link href={game.url}>
                                <a>{game.game_type.name}</a>
                            </Link>
                        </h1>
                        <GameLevel gameLevel={game.game_level} className="mx-1" />
                        <HostGrade hostGrade={game.host_grade} showLevel className="mx-1 d-none" />
                        <span>/ {game.start_time_medium}</span>
                        {game.place && (
                            <div className="enrollment__card__header__address">
                                <span className="icon-geo-point"></span>
                                <GamePlace place={game.place} />
                            </div>
                        )}
                    </div>
                    {game.tournament && (
                        <div className="enrollment__card__header__game-type">
                            <span className="icon-crown-2"></span>
                            <Link href={game.tournament.url}>
                                <a>Турнирная игра</a>
                            </Link>
                        </div>
                    )}
                </div>
                {gameDescription && (
                    <div className="enrollment__card__game-description mb-3">
                        {false && (
                            <div className="enrollment__card__game-num-participants">
                                {gameDescription.num_participants}
                            </div>
                        )}
                        {gameDescription.description}{" "}
                        <Link href={gameDescription.more_link}>
                            <a className="text-primary">Подробнее</a>
                        </Link>
                    </div>
                )}
                {!isMeet4USite() && (game.feature || game.characteristics || game.thomases_scenario) && (
                    <div className="enrollment__card__game-feature">
                        <GameCharacteristics game={game} className="text-body pt-1" />
                        {game.thomases_scenario && (
                            <div className="text-primary pt-1">
                                Сценарий &laquo;{game.thomases_scenario.name}&raquo;
                                <ScenarioRating scenario={game.thomases_scenario} />
                            </div>
                        )}
                        {game.feature && <div>{game.feature}</div>}
                    </div>
                )}

                {showPlacesLeftInfo && (
                    <div className="d-flex justify-content-end mt-n1 mb-n2">
                        <ParticipantCount game={game} className="me-2" />
                    </div>
                )}
                <div className="enrollment__card__details">
                    <div className="enrollment__card__host--wrapper d-flex">
                        {game.host && (
                            <div className="enrollment__card__host me-4">
                                <Avatar player={game.host} size={66} />
                                <div className="enrollment__card__host__info text-muted">
                                    <div>
                                        <strong>{game.host.name}</strong> / ведущий
                                    </div>
                                    <PlayerPeerRating player={game.host} className="d-block mt-1 ms-0" />
                                    {game.club && (
                                        <div className="enrollment__card__host__club d-flex align-items-center my-1">
                                            <ThumbnailPicture
                                                thumbnail={game.club.thumbnail}
                                                width={20}
                                                height={20}
                                                alt={game.club.name}
                                            />
                                            <div className="ms-1 fw-bold">{game.club.name}</div>
                                        </div>
                                    )}
                                    <div>{game.host.feature}</div>
                                </div>
                            </div>
                        )}
                        {game.organizer && (
                            <div className="enrollment__card__host">
                                <Avatar player={game.organizer} size={66} />
                                <div className="enrollment__card__host__info text-muted">
                                    <div>
                                        <strong>{game.organizer.name}</strong> / организатор
                                    </div>
                                    <PlayerPeerRating player={game.organizer} className="d-block mt-1 ms-0" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="d-flex align-items-center ms-2">
                        {game.game_price_amount && (
                            <div className="d-flex flex-column">
                                <div className="enrollment__card__price">
                                    Цена: {game.game_price_amount}{" "}
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={priceInfoPopover}
                                    >
                                        <a tabIndex="0" className="info-button" role="button">
                                            ?
                                        </a>
                                    </OverlayTrigger>
                                </div>
                                <GameExtraInfoPoints
                                    game={game}
                                    className="enrollment__card__price enrollment__card__price--profit"
                                    showFormula
                                />
                            </div>
                        )}
                        {!game.is_cancelled && (
                            <>
                                {game.with_teams ? null : (
                                    <EnrollmentButton
                                        game={game}
                                        onEnrollment={onEnrollment}
                                        setShowEnrollment={setShowEnrollment}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                {game.is_cancelled && <div className="alert alert-danger my-4">Игра была отменена.</div>}

                {game.with_teams && (
                    <GameTeamsSelector
                        gameTeams={gameTeams}
                        selectedGameTeamId={selectedGameTeamId}
                        onSelectGameTeamId={onSelectGameTeamId}
                        className="mt-3 mb-3"
                    />
                )}

                {game.participant_list.length > 0 && (
                    <>
                        <div className="mt-2 mb-n2 text-muted small d-flex align-items-center">
                            <div className="flex-grow-1">
                                <WinnerExperienceGain game={game} className="me-4" />
                            </div>
                            {showPlacesLeftInfo && (
                                <div style={{ minWidth: "198px" }} className="text-center mt-n2">
                                    <PlacesLeftInfo game={game} />
                                </div>
                            )}
                        </div>
                        <div className="enrollment__table">
                            <div className="row">
                                <div className="col-6">
                                    <EnrollmentCardParticipantTable
                                        participantList={participantColumns[0]}
                                        highlightPlayerId={highlightPlayerId}
                                    />
                                </div>
                                <div className="col-6">
                                    <EnrollmentCardParticipantTable
                                        participantList={participantColumns[1]}
                                        highlightPlayerId={highlightPlayerId}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {game.with_teams ? (
                    <div className="text-center mt-4">
                        <EnrollmentButton
                            game={game}
                            onEnrollment={onEnrollment}
                            setShowEnrollment={setShowEnrollment}
                        />
                    </div>
                ) : null}
                {onCollapse && (
                    <div className="text-center">
                        <button className="schedule__expand-link small" onClick={onCollapse}>
                            Свернуть
                        </button>
                    </div>
                )}
            </div>
            {showEnrollment && (
                <EnrollmentModal
                    game={game}
                    gameTeam={gameTeam}
                    onHide={() => setShowEnrollment(false)}
                    onEnrollment={onEnrollment}
                />
            )}
        </>
    );
}
